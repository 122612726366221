/*----------------login css------------------*/

$theme-color: #41a6f7;

.logo-img {
  width: 22vh;
  display: flex;
  padding: 2%;
  margin: auto;
}
#leftImgDiv {
  background-size: cover;
  background-position: center;
  position: relative;
}

#forgtpas {
  float: right;
}

.navTopLeft {
  padding-top: 14px;
}

.app-info-container {
  position: absolute;
  bottom: 12px;
  left: 25%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  margin-top: 80vh;
  h1 {
    margin: auto;
  }
}

.btn-contact {
  padding-right: 42px;
  padding-left: 40px;
  margin-top: 40px;
  color: #44677b;
  font-weight: 700;
  background-color: #f3f0f0 !important;
  height: 35px;
  border-radius: 3px;
  border: 1px solid transparent;
  font-size: 1rem;
}

.copyright-text {
  font-size: 0.8rem;
  margin: auto;
}

.sign-in-text {
  margin: 0;
  font-weight: 500;
}
.signin-form-input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  fieldset {
    border: none;
  }
  > div {
    width: inherit;
  }
}

.signin-form-container {
  background-color: #fbfbfb !important;
  padding-top: 2%;
  p,
  label {
    font-size: 0.8rem;
  }
}
.signin-form {
  input {
    background: #ffffff;
    // box-shadow: 0 1px 15px 1px rgba(62 57 107 / 7%);
    height: 41px;
    box-sizing: border-box;
    border-left: 1px solid #c4c4c4;
    margin-left: 2px;
    padding-left: 5px;
  }
  .input-group {
    margin-bottom: 1rem;
  }
  .btn-signin {
    padding-right: 42px;
    padding-left: 40px;
    margin-top: 10px;
    background-color: $theme-color;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    color: #fff;
    font-weight: 700;
    height: 35px;
    border-radius: 3px;
    border: 1px solid transparent;
    font-size: 1rem;
    cursor: pointer;
    width: 100%;
  }
  .btn-signin:disabled {
    opacity: 0.3;
  }
}

.login-options {
  width: 45%;
  margin: 0 auto;
}

.firebase-sign-in {
  margin-top: 6%;
  display: flex;
  justify-content: center;
  .firebaseui-card-content {
    padding: 0;
  }
  .firebaseui-idp-text {
    color: #757575;
  }
}

.forgot-pass-disabled {
  pointer-events: none;
  opacity: 0.6;
}

#btnReset {
  margin-top: 10px;
  color: #44677b;
  font-weight: 500;
  font-size: 14px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}

#btnReset:disabled {
  opacity: 0.3;
}

.h-md-100 {
  min-height: 100vh;
  height: 100vh;
}

.error-text {
  color: red;
  font-size: 12px;
  margin-top: 3px;
}
.error-border {
  border: 1px solid #ffc0cb !important;
  // box-shadow: 0 1px 15px 1px rgb(255 0 0 / 7%);
}

.login-form-adornment {
  color: $theme-color;
}

.partial-partition {
  width: 40%;
  border: 1px solid #dcdcdc;
  display: inline-flex;
  vertical-align: sub;
}

.partition-or {
  display: inline-flex;
  margin-inline: 2%;
  color: #68727e;
}

.partitioner {
  width: 70%;
  margin: 3% auto 0 auto;
}
.contact-btn {
  color: $theme-color;
  border: 1px solid $theme-color;
}

.contact-btn-container {
  width: 90%;
  display: flex;
  justify-content: flex-end;
}
